hr{
    margin:0px !important;
    padding:0px !important;
}
body{
    font-family:sans-serif !important;
    color:black !important;
    font-weight:normal;
}
h1, h2, h3, h4, h5, h6{
    font-weight:normal !important;
}
.challan_header{
    font-weight: 700 !important;
}
.bill_of_exchange_header{
    font-weight: 600 !important;
}   
p + p {
    margin-top: 0px !important;
}

th,td{
    vertical-align: middle !important;
    /* padding-top: 0px !important;
    padding-bottom: 0px !important; */
} 

tbody td{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.roll_size_td{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

th{
    text-align: center;
}
.yellow_row td{
    background-color: rgba(243, 239, 155, 0.748);
}
.green_row td{
    background-color: rgba(0, 255, 0, 0.23);
}
.red_row td{
    background-color: #f6b8cd;
}
.pink_row td{
    background-color: #f6b8cd;
}
.red_cell{
    background-color: rgba(255, 0, 0, 0.23);
}
.disapproved_order td{
    background-color: rgba(255, 0, 0, 0.33);
}
.search_container input[type=text] {
  padding: 10px;
  font-size: 17px;
  /* border: ; */
  float: left;
  width: 80%;
  border-radius: 3px 0px 0px 3px;
}

.search_container div {
  float: left;
  width: 20%;
  padding: 10px;
  background: #2196F3;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
  border-radius: 0px 3px 3px 0px;
}

.search_container div:hover {
  background: #0b7dda;
}

.job_no{
    color: blue;
}

.job_no:hover{
    cursor: pointer;
    text-decoration: underline;
}


/* .underline {
  color: blue;
} */
.underline:hover {
  cursor: pointer;
  text-decoration: underline;
}
/* style={{backgroundColor:'rgb(0,0,0,0.1)',color:'black',border:'1px solid grey' }} */
.add_to_pp_button{
    background-color: rgb(0,0,0,0.1);
    color: black;
    border: 1px solid grey;
}

.add_to_pp_button:hover{
    background-color: rgb(0,0,0,0.2);
    color:black;
    border: 1px solid grey;
}
/* style={{ width: '20px', height: '20px', cursor: 'pointer' }} */
.dustbin_img{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.dustbin_img_small{
    width: 14px;
    height: 17px;
    cursor: pointer;
}

/* style={{color:'#337ab7', marginRight:'10px', cursor:'pointer'}} */
#edit_icon{
    color: #337ab7;
    margin-right: 10px;
    cursor: pointer;
}

#add_finishing_icon{
    color: #337ab7;
    margin-right: 30px;
    cursor: pointer;
    font-size: 20px;
}


#add_finishing_icon:hover{
    color: #286192;
    margin-right: 30px;
    cursor: pointer;
    font-size: 22px; /* Increase the font size to make the icon bigger */
}

#add_challan_icon{
    color: black;
    cursor: pointer;
    font-size: 20px;
}


#add_challan_icon:hover{
    cursor: pointer;
    font-size: 24px; /* Increase the font size to make the icon bigger */
}

.challan_plus_icon{
    font-size: 17px;
    color: black;
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: 0px;
}
.challan_plus_icon:hover{
    font-size:22px;
}
.challan_cross_icon{
    color: red;
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -10px;
}

.challan_cross_icon:hover{
    font-size:18px;
}

p + p {
    margin-top: 0px;
}

.edit_challan_text{
    color: #337ab7;
    cursor: pointer;
}
.edit_challan_text:hover{
    text-decoration: underline;
}
/* style={{backgroundColor:'rgba(0,0,0,0.1)',textDecoration:'none',border:'0px',marginRight:'30px', color:'black',padding:'8px 12px'}}  */
.manage_deli_challan_btn{
    background-color: rgba(0,0,0,0.1);
    text-decoration: none;
    border: 0px;
    margin-right: 30px;
    color: black;
    padding: 8px 12px;
}

.manage_deli_challan_btn:hover{
    background-color: rgba(0,0,0,0.2);
    text-decoration: none;
    color: black;
}

.gate_pass_tick{
    font-size: 18px;
    color: #337ab7;
}

.gate_pass_tick:hover{
    color: #286192;
    cursor: pointer
}

.create_bill_btn {
    background-color: #f0ad4e;
    color: black;
    border: none;
    cursor: pointer;
    padding: 10px 15px;   
    border-radius: 5px;  
    font-size: 16px;      
    text-align: center;   
    display: inline-block; 
    transition: background-color 0.3s ease;
}

.create_bill_btn:hover{
    background-color: #ec971f;
    color: black;
}

.custom-modal .modal-dialog {
    max-width: 100%;
    /* width: auto !important; */
}

.custom-modal{
    display:flex !important;
}

/* .custom-modal .modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
} */
  

.print-button{
    color: #337ab7;
    font-size: 15px;
}

.print-button:hover{
    color: #286192;
    cursor: pointer;
}
#pi_edit_icon{
    color: #337ab7;
    margin-left: 10px;
    cursor: pointer;
}

#commercial_edit_icon{
    color: #337ab7;
    margin-left: 10px;
    cursor: pointer;
    font-size:16px;
}

.vertical_align_bottom{
    vertical-align: bottom !important;
}  

.challan_hr{
    margin-top: 5px !important;
}

.challan_pdf_table td{
    border: 1px solid black;
    padding: 8px 10px !important;
    font-size:12px;
}

.faithfully_challan{
    padding: 0px 0px 50px 0px !important;
}

.challan_footer{
    padding: 25px 0px !important;
}

.gate_pass_pdf{
     padding: 140px 0 20px !important;
}

.in_words_pi{
    padding: 8px 10px !important;
}

/* #edit_icon_party{
    font-size:18px;
    vertical-align: middle;
    cursor: pointer;
}

#edit_icon_party:hover{
    color: grey;
    font-size:19px;
} */

#edit_icon_party {
    /* color: #337ab7; */
    font-size: 18px;
    vertical-align: middle;
    cursor: pointer;
    /* transform: scale(1); */
    /* transition: transform 0.2s ease-in-out; */
}

#edit_icon_party:hover {
    color: grey;
    transform: scale(1.10);
}

.group_pi_terms_and_conditions td{
    padding: 0px 0px !important;
}

.work_order_info td{
    padding: 0px 0px !important;
    vertical-align: top !important;
}

.no_padding_no_vertical_align td{
    padding: 0px 0px !important;
    vertical-align: top !important;
}

.certificate_table tbody tr td{
    padding: 0px 0px !important;
    vertical-align: top !important;
}

.group_pi_to_and_address{
    vertical-align: top !important;
}